.grid-cell {
  --grid-column-span-mobile: 1;
  --grid-column-span-tablet: 1;
  --grid-column-span-desktop: 1;
  --grid-column-span: var(--grid-column-span-mobile);

  grid-column: span var(--grid-column-span);
}

@media screen(tablet) {
  .grid-cell {
    --grid-column-span: var(--grid-column-span-tablet);
  }
}

@media screen(desktop) {
  .grid-cell {
    --grid-column-span: var(--grid-column-span-desktop);
  }
}
